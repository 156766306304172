<script>
  import { conf, permissions, modal, lang } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, getById, inArray, asArray, isSame, genCode, anchor, clipBoard } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'


  let versions=[ 
    "all"
  ]
 
  let typeOptions=[ 
    { name: "android", id: "android" },
    { name: "ios", id: "ios" },
    { name: "pwa", id: "pwa" }
  ]


  let objs=[], obj={}, keyword="", sortBy={notifyDate:-1}, filter={valid:false}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.notifications) $modal={
      cancel:true,
      title:i18n("licence--No more notifications"), 
      msg:i18n("licence--No more notifications msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else setRoute("/notifications/edit")
  }


  async function edit(id){
    setRoute(`/notifications/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/notifications/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/notifications")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/notifications/get/${id}`)
    obj={ ...obj }
  }

  isSame("listNotifications","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listNotifications", checkArgs)) return
    let req={ ...filter, ...(tagsSelected.length?{tags: { $in: tagsSelected}}:{})}
    objs = await api(`/app/customers/notifications/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(id){
    if(id) obj=getById(objs, id) //use in list action
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.title)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/notifications/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/notifications")
    }}
  }

  async function duplicate(id){
    if(id) obj=getById(objs, id) //use in list action
    $modal={cancel:true, title:`${i18n("notifications--Duplicate")} ${i18n(obj.title)}`, color:'warning', confirm:async ()=>{
      let data=await api(`/app/customers/notifications/clone/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/notifications")
    }}
  }


  let targets=[]
  getTargets()
  async function getTargets(){
    targets = await api(`/app/customers/targets/list`)
  }

  let tags=[]
  getTags()
  async function getTags(){
    let catExcludedIds=[1010] //ancienne publication
    let rootApiUrl="https://www.bois-colombes.fr"
    let c1 = await fetch(`${rootApiUrl}/wp-json/wp/v2/categories?per_page=100&exclude=${catExcludedIds}`)
    let c2 = await fetch(`${rootApiUrl}/wp-json/wp/v2/categorie_evenement?per_page=100`)
    c1 = await c1.json()
    c2 = await c2.json()
    tags=[...c1, ...c2].sort((a, b) => (a.name > b.name) ? 1 : -1)
  }
</script>


<CustomerBanner/>


<Route path="/notifications/*" pathHide="/notifications/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}
      bind:filter filters={[
        { filter: {valid:false}, title:'g--Sent' },
        { filter: {valid:true}, title:'g--Planned' }
      ]}
  
      bind:sortBy sortBys={[
        { id:'notifyDate', title:'g--By date', desc:true},
        { id:'sendCount', title:'g--By send count', desc:true},
        { id:'clickCount', title:'g--By click count', desc:true},
        { id:'title', title:'g--By title' }
      ]}
    />

    <List type="card" cardShadow cardUp cardFooterDate="notifyDate" refresh={()=>list()} title="g--notifications" {objs}>
      <div slot="header" class="w-100 d-flex justify-content-between" let:obj>
        <div>{i18n(obj.title)}</div>
        <div class="m-1 text-nowrap"><I me chart-line-up/> {obj.sendCount || 0}</div>
        <div class="m-1 text-nowrap"><I me bullseye-pointer/> {obj.clickCount || 0}</div>
      </div>
      <div slot="body" let:obj>
        <div class="row m-3">
          <div on:click={()=>edit(obj.id)} class="col-8 pointer text-success"><I me edit/><I18n>notifications--Edit</I18n></div>
          
          <div class="col-4 small text-nowrap">
            <div on:click={()=>duplicate(obj.id)} class="pointer text-primary"><I me copy/><I18n>notifications--Duplicate</I18n></div>
            <div on:click={()=>del(obj.id)} class="pointer text-danger"><I me trash/><I18n>g--Delete</I18n></div>
          </div>
        </div>
        <div>{#each asArray(obj.tags) as tag}<span class="badge bg-secondary me-1">{getById(tags,tag).name}</span>{/each}</div>
      </div>
    </List>
  </div>
</Route>


<Route path="/notifications/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="notification" detail={obj.title} back="/notifications"/>

      <div class="anchor" on:click={anchor}>
        <span><I18n>General</I18n></span> |
        <span><I18n>Schedule</I18n></span> |
        <span><I18n>Options</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" label="General" icon="info"/>
          <Input class="col-sm-12" bind:value={obj.title} icon="fa-bullhorn" type="text" label="g--Title" multilang translator required/>
          <!--Input disabled class="col-sm-3" bind:value={obj.logo} accept="image/png,image/jpeg,image/gif,image/svg" type="file" label="g--Logo" icon/-->
          
          <Input class="col-sm-12" bind:value={obj.message} icon type="textarea" rows=5 label="g--Message" multilang translator required/>
          <Input class="col-sm-12" bind:value={obj.url} icon="fa-link" type="url" label="g--Link" />
          <Input disabled set="" class="col-sm-12" bind:value={obj.itemId} icon="fa-hash" type="text" label="g--Item id" />
          
          <Input type="hr" label="g--Schedule" icon="clock"/>
          <Input class="col-sm-4" set={new Date()} bind:value={obj.notifyDate} icon type="datetime" label="g--date" help="g--help date lower" required/>
          <Input class="col-sm-8" lg set={false} bind:value={obj.valid} type="switch" label=" " option="g--ready to send at date" help="g--help ready to send at date"/>
          
          <Input type="hr" label="g--Options" icon="cogs"/>
          <Input set={['android','ios','pwa']} inline class="col-sm-3" bind:value={obj.type} options={typeOptions} icon="fa-mobile-notch" type="checkbox" label="g--devices"/>
          <Input set={targets[0].id} class="col-sm-5" bind:value={obj.targetId} options={targets} icon="fa-bullseye" type="select" label="g--target"/>
          <Input set="all" class="col-sm-4" bind:value={obj.version} options={versions} icon="fa-code-branch" type="select" label="g--Version"/>
      
          <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} icon="tags" bind:value={obj.tags} type="switch" options={tags} label="g--tags" helpBefore="g--help tags"/>
  

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>