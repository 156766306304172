<script>
  import { permissions, customers, customerId, route, wsc, conf, userId, user, token, lang, alert, menus } from './stores.js'
  import { setRoute, setRouteRoot} from './h.js'

  import Window from './G_Window.svelte'
  import Route from './G_Route.svelte'

  import Dashboard from './L_Dashboard.svelte'
  import Notifications from './L_Notifications.svelte'
  import Tags from './L_Tags.svelte'
  import Targets from './L_Targets.svelte'
  
  let localConf={
    faType:"fa-light",
    url:"https://tweekl.com",
    langs:['fr','en'],
    i18n:{},
    perms:[
      {id:"users", name:"member--manage team"}
    ],
    permsAgency:[
      {id:"customers", name:"agency--manage agency customers"},
      {id:"users", name:"agency--manage agency users"}
    ],
    account:{
      withDomains:false,
      withBilling:false,
      withApi:false,
      withDPO:false,
      withComparePlan:false,
      withLicence:true,
      withLicenceDetail:true
    },
    agency:{
      withLicenceAgencyCustomer:true,
      withLicenceAgencyCustomerDetail:true
    }
  }

  $: $menus=[
    {route: '/dashboard', name: '', icon:'home', valid:$customers},
    {route: '/homenocustomer', name: '', icon:'home', valid:!$customers},
    {route: '/notifications', name: 'Notifications', icon:'bell', valid:$customers},
    {route: '/tags', name: 'Tags', icon:'tags', valid:$customers},
    {route: '/targets', name: 'Targets', icon:'bullseye-arrow', valid:$permissions.admin},
    {route: '/account', name: 'Account', icon:'wrench', valid:$permissions.admin || $permissions.users || $permissions.informations},
    {route: '/agency', name: 'Agency', icon:'globe', valid:$user.perms?.valid},
    {route: '/translations', name: 'Translations', icon:'university', valid:$user.perms?.translation}
  ]
</script>

<Window {localConf}>
  {#key $customerId}
    <Route path="/homenocustomer" fsContent>
      Hello home no customer
    </Route>


    <Route path="/dashboard" fsContent>
      <Dashboard/>
    </Route>

    <Route path="/notifications/*" fsContent>
      <Notifications/>
    </Route>

    <Route path="/tags/*" fsContent>
      <Tags/>
    </Route>
    <Route path="/targets/*" fsContent>
      <Targets/>
    </Route>
  {/key}
</Window>